/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const publishAggregatedPlant = /* GraphQL */ `
  subscription PublishAggregatedPlant {
    publishAggregatedPlant {
      areaCode
      plantTotal
      gardenTotal
      riversAndCoastalTotal
      treeTotal
      flowerTotal
      fruitVegTotal
      total
    }
  }
`;
export const onCreatePlant = /* GraphQL */ `
  subscription OnCreatePlant {
    onCreatePlant {
      id
      areaCode
      areaDescriptiveName
      plantTotal
      gardenTotal
      riversAndCoastalTotal
      treeTotal
      flowerTotal
      fruitVegTotal
      type
      adminUser
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePlant = /* GraphQL */ `
  subscription OnUpdatePlant {
    onUpdatePlant {
      id
      areaCode
      areaDescriptiveName
      plantTotal
      gardenTotal
      riversAndCoastalTotal
      treeTotal
      flowerTotal
      fruitVegTotal
      type
      adminUser
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePlant = /* GraphQL */ `
  subscription OnDeletePlant {
    onDeletePlant {
      id
      areaCode
      areaDescriptiveName
      plantTotal
      gardenTotal
      riversAndCoastalTotal
      treeTotal
      flowerTotal
      fruitVegTotal
      type
      adminUser
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAggregatedPlant = /* GraphQL */ `
  subscription OnCreateAggregatedPlant {
    onCreateAggregatedPlant {
      areaCode
      plantTotal
      gardenTotal
      riversAndCoastalTotal
      treeTotal
      flowerTotal
      fruitVegTotal
      total
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAggregatedPlant = /* GraphQL */ `
  subscription OnUpdateAggregatedPlant {
    onUpdateAggregatedPlant {
      areaCode
      plantTotal
      gardenTotal
      riversAndCoastalTotal
      treeTotal
      flowerTotal
      fruitVegTotal
      total
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAggregatedPlant = /* GraphQL */ `
  subscription OnDeleteAggregatedPlant {
    onDeleteAggregatedPlant {
      areaCode
      plantTotal
      gardenTotal
      riversAndCoastalTotal
      treeTotal
      flowerTotal
      fruitVegTotal
      total
      createdAt
      updatedAt
    }
  }
`;
