import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import configureStore from './store/configureStore';
import { CookiesProvider } from 'react-cookie';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import Amplify, { Analytics } from 'aws-amplify';
import awsExports from "./aws-exports";

import './index.scss';

Sentry.init({
  dsn: "https://7e085181414d4abd99cca533c3c6d94c@o237149.ingest.sentry.io/5532281",
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 0.2,
  ignoreErrors: ['ChunkLoadError', /WebGL/i, /Loading chunk/i]
});

Amplify.configure(awsExports);
Analytics.record({ name: 'mapVisit' });

const store = configureStore();

const setVh = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

setVh();

window.addEventListener('resize', () => {
  setVh();
});


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();