import { API } from 'aws-amplify';
import { SET_TYPE, SET_GEOJSON, SET_BG, SET_SEASON, ADD_PLANTING, UPDATE_PLANTING } from './constants';

export function setType(payload) {
    return {
        type: SET_TYPE,
        payload,
    };
}

export function setGeoJson(payload) {
    return {
        type: SET_GEOJSON,
        payload,
    };
}

export function setBg(payload) {
    return {
        type: SET_BG,
        payload,
    };
}

export function setSeason(payload) {
    return {
        type: SET_SEASON,
        payload,
    };
}

export function addPlanting(payload) {
    return {
        type: ADD_PLANTING,
        payload,
    };
}

export function updatePlanting(payload) {
    return {
        type: UPDATE_PLANTING,
        payload,
    };
}

const updateFeatures = (geojson, data) => {
    geojson.features.map((feature) => {
        const { gardenTotal, flowerTotal, fruitVegTotal, plantTotal, treeTotal, riversAndCoastalTotal, total } = { ...data.find(d => d.areaCode === feature.properties.name) };
        const totals = {
            gardenTotal: gardenTotal || 0,
            riversAndCoastalTotal: riversAndCoastalTotal || 0,
            flowerTotal: flowerTotal || 0,
            fruitVegTotal: fruitVegTotal || 0,
            plantTotal: plantTotal || 0,
            treeTotal: treeTotal || 0,
            total: total || 0
        };

        feature.properties = {
            ...feature.properties,
            ...totals,
        };
        return feature;
    });
    return geojson;
};

export function fetchPlants(url) {
    return async (dispatch) => {
        try {
            const listAggregatedPlants =
            `query listAggregatedPlants {
                listAggregatedPlants(limit: 242) {
                    items {
                        areaCode
                        flowerTotal
                        fruitVegTotal
                        gardenTotal
                        plantTotal
                        treeTotal
                        riversAndCoastalTotal
                        total
                    }
                }
            }`;

            const aggregatedData = await API.graphql({ query: listAggregatedPlants });
            const postcodes = await fetch('https://d159os3caowhrk.cloudfront.net/postcode.json').then(response => response.json());
            const geojson = updateFeatures(postcodes, aggregatedData.data.listAggregatedPlants.items);
            dispatch(setGeoJson(geojson));
            const bg = await fetch('https://d159os3caowhrk.cloudfront.net/UK.json').then(response => response.json());
            dispatch(setBg(bg));
        } catch (error) {
            console.log(error);
        }
    };
}