import React from 'react';
import { IntroLogo } from './IntroStyles';
import { ModalLayout, ModalWrapper, ModalButton } from './../../styles/sharedStyles';
import Logo from './plant-britain-logo.png';

const Intro = ({ setShowIntro }) => (
  <ModalLayout data-testid="Intro">
    <ModalWrapper>
      <IntroLogo>
        <img src={Logo} alt="Plant Britain" />
      </IntroLogo>
      <p>Countryfile’s Plant Britain aims to get the nation planting.</p>
      <p>Share your planting and coastal clean-ups with us on our map and see what others across the country have achieved.</p>
      <p>Together let’s Plant Britain!</p>
      <ModalButton onClick={() => setShowIntro(false)}>Get started</ModalButton>
    </ModalWrapper>
  </ModalLayout>
);

export default Intro;
