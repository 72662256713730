import { ReactComponent as AllPlantings } from './styles/icons/all-plantings.svg';
import { ReactComponent as Flowers } from './styles/icons/flowers.svg';
import { ReactComponent as FruitVeg } from './styles/icons/fruit-veg.svg';
import { ReactComponent as Plant } from './styles/icons/plant.svg';
import { ReactComponent as Trees } from './styles/icons/trees.svg';
import { ReactComponent as Garden } from './styles/icons/all-plantings.svg';
import { ReactComponent as Shell } from './styles/icons/shell.svg';
import postcodeData from './data/postcodeArea';

export const SEASONS = [
    'spring',
    'summer',
    'autumn',
    'winter'
];

export const season = () => {
    const month = new Date().getMonth() + 1;
    if (month >= 3 && month <= 5) {
        return SEASONS[0];
    }

    if (month >= 6 && month <= 8) {
        return SEASONS[1];
    }

    if (month >= 9 && month <= 11) {
        return SEASONS[2];
    }

    return SEASONS[3];
};

export const plantTypes = [
{
    type: 'total',
    title: 'All plantings',
    header_title: 'All plantings',
    description: ''
},
{
    type: 'riversAndCoastalTotal',
    title: 'Coastal Clean-Ups',
    nav_title_override: 'Clean-Ups',
    header_title: 'Total clean-ups',
    description: 'Rivers and coastal'
},
{
    type: 'gardenTotal',
    title: 'Gardens',
    header_title: 'Total gardens',
    description: 'Community gardens'
},
{
    type: 'treeTotal',
    title: 'Trees',
    header_title: 'Total trees',
    description: 'Individual saplings'
},
{
    type: 'flowerTotal',
    title: 'Flowers',
    header_title: 'Total flowers',
    description: 'Full grown flowers or packets of seeds'
},
{
    type: 'plantTotal',
    title: 'Plants',
    header_title: 'Total plants',
    description: 'Full grown plants or packets of seeds'
},
{
    type: 'fruitVegTotal',
    title: 'Fruit & Veg',
    header_title: 'Total fruit & veg',
    description: 'Full grown plants or packets of seeds'
}];

export const getIcon = (type) => {
    switch (type) {
        case 'total':
            return (<AllPlantings />);
        case 'treeTotal':
            return (<Trees />);
        case 'gardenTotal':
            return (<Garden />);
        case 'flowerTotal':
            return (<Flowers />);
        case 'plantTotal':
            return (<Plant />);
        case 'fruitVegTotal':
            return (<FruitVeg />);
        case 'riversAndCoastalTotal':
            return (<Shell />);
        default:
            break;
    }
};

export const enableTabbing = () => {
    const handleFirstTab = e => {
        if (e.keyCode === 9) {
            document.body.classList.add('tabbing');
            window.removeEventListener('keydown', handleFirstTab);
            // eslint-disable-next-line
            window.addEventListener('mousedown', handleMouseDownOnce);
        }
    };
    const handleMouseDownOnce = () => {
        document.body.classList.remove('tabbing');
        window.removeEventListener('mousedown', handleMouseDownOnce);
        window.addEventListener('keydown', handleFirstTab);
    };
    window.addEventListener('keydown', handleFirstTab);
};

export const getPostcodeName = (code) => {
    const getPostcode = postcodeData.find(p => p.Postcode === code);
    return getPostcode ? getPostcode.Name : '';
};