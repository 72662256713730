import Main from './layout/Main/Main';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/helpers';
import { season } from './util';
import { API, graphqlOperation } from 'aws-amplify';
import { publishAggregatedPlant } from './graphql/subscriptions';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectSeason } from './store/state/selectors';
import { updatePlanting } from './store/state/actions';

function App() {
    const dispatch = useDispatch();
    const [currentTheme, setTheme] = useState(theme[season()]);
    const selectedSeason = useSelector(selectSeason);

    useEffect(() => {
        setTheme(theme[selectedSeason]);
    }, [selectedSeason]);

    useEffect(() => {
        const subscription = API.graphql(
            graphqlOperation(publishAggregatedPlant)
        ).subscribe({
            next: event => {
                if (event.value.data && event.value.data.publishAggregatedPlant) {
                    console.log(event.value.data.publishAggregatedPlant);
                    dispatch(updatePlanting(event.value.data.publishAggregatedPlant));
                }
            },
            error: error => {
                console.error(error);
            }
        });
        return () => {
            subscription.unsubscribe();
        };
    }, [dispatch]);

    return (
        <ThemeProvider theme={currentTheme}>
            <Main />
        </ThemeProvider>
    );
}

export default App;
