/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:6abecd7f-daf4-4ad1-91ff-7b69fbd2a638",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_dkSuq60Ze",
    "aws_user_pools_web_client_id": "2j70n3rb9qbhmh9j4s95lb3cee",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://k3lptt5ypzcylcvl7krqaigorm.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AWS_IAM",
    "aws_cloud_logic_custom": [
        {
            "name": "verify",
            "endpoint": "https://dtnz920lm6.execute-api.eu-west-2.amazonaws.com/master",
            "region": "eu-west-2"
        }
    ],
    "aws_mobile_analytics_app_id": "466aa8d4ca2c4b9486d5cda8c0a34436",
    "aws_mobile_analytics_app_region": "eu-west-2"
};


export default awsmobile;
